<template>
  <div class="form-group">
    <label :for="id">{{ label }}</label>
    <input class="form-control" :id="id"
        :type="type"
        :value="value"
        v-bind="$attrs"
        v-on="inputListeners">
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    label: String,
    value: [String, Number],
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "text",
    },
  },
  computed: {
    inputListeners: function () {
      var vm = this
      // `Object.assign` が複数のオブジェクトを一つの新しいオブジェクトにマージします
      return Object.assign({},
        // 親からの全てのリスナを追加します
        this.$listeners,
        // そしてカスタムリスナを追加したり
        // すでに存在するリスナの振る舞いを変えることができます
        {
          // こうすることでコンポーネントが v-model と動作します
          input: function (event) {
            vm.$emit('input', event.target.value)
          }
        }
      )
    }
  }
}
</script>