<template>
  <div id="rule" class="container">
    <h3>{{ $t('label.rules') }}</h3>

    <div v-if="isClosed" class="alert alert-info">
      <font-awesome-icon icon="exclamation-triangle" />
      {{ $t('message.immute') }}
    </div>

    <base-input
        :class="{ 'form--error': $v.form.maxPlayer.$error }"
        id="max-player"
        :label="$t('label.maxPlayer')"
        type="number"
        v-model.number="$v.form.maxPlayer.$model"
        :disabled="isClosed">
    </base-input>
    <div v-if="!$v.form.maxPlayer.required" key="maxplayer-error" class="form--error-message">
      {{ $t('message.required') }}
    </div>
    <div v-else-if="!$v.form.maxPlayer.minValue" key="maxplayer-error" class="form--error-message">
      {{ $t('message.minValue', { num: $v.form.maxPlayer.$params.minValue.min }) }}
    </div>

    <base-input
        :class="{ 'form--error': $v.form.maxRound.$error }"
        id="max-round"
        :label="$t('label.maxRound', { recommended: recommendedRound })"
        type="number"
        v-model.number="$v.form.maxRound.$model"
        :disabled="isClosed">
    </base-input>
    <div v-if="!$v.form.maxRound.required" key="maxround-error" class="form--error-message">
      {{ $t('message.required') }}
    </div>
    <div v-else-if="!$v.form.maxRound.minValue" key="maxround-error" class="form--error-message">
      {{ $t('message.minValue', { num: $v.form.maxRound.$params.minValue.min }) }}
    </div>

    <base-input
        :class="{ 'form--error': $v.form.pointWin.$error }"
        id="point-win"
        :label="$t('label.pointWin')"
        type="text"
        v-model.number="$v.form.pointWin.$model"
        :disabled="isClosed">
    </base-input>
    <div v-if="!$v.form.pointWin.required" key="pointwin-error" class="form--error-message">
      {{ $t('message.required') }}
    </div>
    <div v-else-if="!$v.form.pointWin.decimal" key="pointwin-error" class="form--error-message">
      {{ $t('message.decimal') }}
    </div>
    <div v-else-if="!$v.form.pointWin.minValue" key="pointwin-error" class="form--error-message">
      {{ $t('message.minValue', { num: form.pointDraw }) }}
    </div>

    <base-input
        :class="{ 'form--error': $v.form.pointDraw.$error }"
        id="point-draw"
        :label="$t('label.pointDraw')"
        type="text"
        v-model.number="$v.form.pointDraw.$model"
        :disabled="isClosed">
    </base-input>
    <div v-if="!$v.form.pointDraw.required" key="pointdraw-error" class="form--error-message">
      {{ $t('message.required') }}
    </div>
    <div v-else-if="!$v.form.pointDraw.decimal" key="pointdraw-error" class="form--error-message">
      {{ $t('message.decimal') }}
    </div>
    <div v-else-if="!$v.form.pointDraw.minValue" key="pointdraw-error" class="form--error-message">
      {{ $t('message.minValue', { num: $v.form.pointDraw.$params.minValue.min }) }}
    </div>

    <div class="mb-2 tiebreak">
      <div class="form-label">{{ $t('label.tiebreaks') }}</div>
      <div class="row">
        <div class="col-sm-6">
          <b>{{ $t('label.enabled') }}</b>
          <table class="table table-hover">
            <thead>
              <tr>
                <th class="col-index">{{ $t('label.order')}}</th>
                <th>{{ $t('label.name') }}</th>
                <th class="col-handle"></th>
              </tr>
            </thead>
            <draggable
              v-model="$v.form.tieBreaksEnabled.$model"
              group="tie-break"
              tag="tbody"
              draggable=".item"
              handle=".handle"
              @start="drag=true"
              @end="drag=false">
              <tr v-for="(tb, idx) in $v.form.tieBreaksEnabled.$model" :key="tb.key"
                :class="{
                  'item': !tb.priorityFixed,
                  'text-secondary': tb.priorityFixed,
                }">
                <td>{{ idx + 1 }}</td>
                <td>{{ tb.path && $t(tb.path) || tb.name }}</td>
                <td>
                  <font-awesome-icon v-if="!tb.priorityFixed && !isClosed" icon="grip-lines" class="handle" />
                </td>
              </tr>
            </draggable>
          </table>
        </div>
        <div class="col-sm-6">
          <b>{{ $t('label.disabled') }}</b>
          <table class="table table-hover">
            <thead>
              <tr>
                <th class="col-index"></th>
                <th>{{ $t('label.name') }}</th>
                <th class="col-handle"></th>
              </tr>
            </thead>
            <draggable
              v-model="$v.form.tieBreaksDisabled.$model"
              group="tie-break"
              tag="tbody"
              draggable=".item"
              handle=".handle"
              @start="drag=true"
              @end="drag=false">
              <tr v-for="tb in $v.form.tieBreaksDisabled.$model" :key="tb.key"
                :class="{
                  'item': !tb.priorityFixed,
                  'text-secondary': tb.priorityFixed,
                }">
                <td></td>
                <td>{{ tb.path && $t(tb.path) || tb.name }}</td>
                <td><font-awesome-icon v-if="!isClosed" icon="grip-lines" class="handle" /></td>
              </tr>
            </draggable>
          </table>
        </div>
      </div>
    </div>

    <div class="mb-2">
      <button type="button" class="btn btn-outline-info" :disabled="$v.$invalid" @click="saveDefaultRule">{{ $t('label.saveAsDefault') }}</button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { required, minValue, integer, decimal, or } from 'vuelidate/lib/validators'
import Draggable from 'vuedraggable'
import BaseInput from '@/components/BaseInput.vue'
import localStorage from '@/persistent/localstorage'

export default {
  name: 'Rule',
  components: {
    BaseInput,
    Draggable,
  },
  data() {
    const rule = this.$store.state.event.rule
    return {
      form: {
        maxPlayer: rule.maxPlayer,
        maxRound: rule.maxRound,
        pointWin: rule.pointWin,
        pointDraw: rule.pointDraw,
        tieBreaksEnabled: rule.tieBreaksEnabled,
        tieBreaksDisabled: rule.tieBreaksDisabled,
      },

    }
  },
  computed: {
    recommendedRound() {
      return Math.ceil(Math.log2(this.form.maxPlayer))
    },
    isClosed() {
      return this.$store.state.event.round.count > 0
    },
  },
  validations() {
    return {
      form: {
        maxPlayer: {
          required,
          integer,
          minValue: minValue(4),
        },
        maxRound: {
          required,
          integer,
          minValue: minValue(2),
        },
        pointWin: {
          required,
          decimal,
          minValue: or(minValue(this.form.pointDraw), () => isNaN(this.form.pointDraw)),
        },
        pointDraw: {
          required,
          decimal,
          minValue: minValue(0),
        },
        tieBreaksEnabled: {
          $each: {}
        },
        tieBreaksDisabled: {
          $each: {}
        },
      },
    }
  },
  methods: {
    setRule() {
      if(this.isClosed) {
        console.error('rules are immutable')
      } else if(this.$v.$invalid) {
        // error
        console.error('Some rules are invalid');
      } else {
        this.$store.commit('event/setRule', this.form)
        this.$v.$reset()
      }
    },
    saveDefaultRule() {
      if(this.$v.$invalid) {
        // error
        console.error('Some rules are invalid');
      } else {
        localStorage.setItem('defaultRules', JSON.stringify(this.form))
        this.$toast.success('Default rule saved.')
      }
    },
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.setRule()
      }
    }
  },
}
</script>
<style scoped>
.form--error >>> .form-control {
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.form--error-message {
  font-size: small;
  color: #f57f6c;
}

.form-group {
  margin-bottom: 1.75rem;
}

.form-group + .form--error-message {
  margin-top: -1.2rem;
}

.form-label {
  margin-bottom: 0.5rem;
}

.tiebreak .row {
  margin: auto 0.5rem;
}

.col-handle {
  width: 2rem;
}

.col-index {
  width: 5rem;
}

.handle {
  cursor: move;
}
</style>
<i18n>
{
  "en": {
    "label": {
      "rules": "Rules",
      "maxPlayer": "Max number of players",
      "maxRound": "Max number of rounds (Recommended: {recommended})",
      "pointWin": "Win point",
      "pointDraw": "Draw point",
      "tiebreaks": "Tiebreaks",
      "enabled": "Enabled",
      "disabled": "Disabled",
      "order": "Order",
      "name": "Name",
      "saveAsDefault": "Save as default"
    },
    "message": {
      "immute": "Rules cannot be modified after a game started."
    }
  },
  "ja": {
    "label": {
      "rules": "ルール",
      "maxPlayer": "最大参加人数",
      "maxRound": "最大試合数 (推奨: {recommended})",
      "pointWin": "勝利時の点数",
      "pointDraw": "引き分け時の点数",
      "tiebreaks": "タイブレーク",
      "enabled": "有効",
      "disabled": "無効",
      "order": "適用順",
      "name": "名称",
      "saveAsDefault": "初期設定として保存"
    },
    "message": {
      "immute": "試合開始後にルールを変更することはできません。"
    }
  }
}
</i18n>
